<template>
<vue-element-loading :active="active" :is-full-screen="isFullScreen">
    <img src="../assets/pikachu.gif" class="h-14" />
</vue-element-loading>
</template>

<script>
import VueElementLoading from 'vue-element-loading'

export default {
    props: {
        active: {

        },
        isFullScreen: {
            default: false
        }
    },
    components: {
        VueElementLoading
    }
}
</script>

<style scoped>

</style>